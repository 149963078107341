import React, { Component } from "react";
import "./index.scss";
import { Input, Select, Button, Table, Modal, Drawer, Form, Upload, Empty, Checkbox, Radio, message, Spin,Row, Col }
  from "antd";
import { uploadFile } from "../../../api/ali";
import MaxLengthInput from "../../../components/hhinput";
import EditdirModule from './editdirModule'
import LookDir from './lookDir'

import AddZhangJie from './addZhangJie'
import { Yichang, Word, Xiazai, HuiXian, SouT, Shanchuicon, LodingBase64 }
  from "../../../components/shixunsvg1.20/index";
import { getValidTimeEnumList, removeCurriculumop, eduSubList, createCurriculumop,
  getEducationStageEnumList, Curriculumoptionlist, getResourceTypeEnumList,
  listByResourceId, resourcesupplieroptionlist, Curriculumopdetail, getResourceRunModeEnumList,
  getResourceAdaptiveHardwareEnumList, createAddr, getUserInfoByTel, resourcedownloadSend,
  editCurriculumop, resourceorgJointLevelList, resourcechapterlist,
  resourcechapteroptionList, pageListdara, getStartTypeEnumList, getAllResourceTypeEnumList
} from "../../../api/shixun2/index";
import { saveAs } from 'file-saver'
import UploadImg from '../../../components/upload_img/index'
const { Option } = Select
export class index extends Component {

  state = {
    pageNum: 1,
    pageSize: 10,
    total: 0,
    pageNumon: 1,
    pageSizeon: 10,
    totalon: 0,
    isTrue: null,
    visible: false, //查看
    operation: false, //操作记录
    Versionnumber: false, //版本号i
    addvisible: false, //添加
    dataSource: [], //表格数据
    dataSourceloading: true, //表格数据loding
    dataSourceTable: [], // 操作记录Table
    dataSourceTableloading: true, //操作记录Table数据loding
    //版本号的
    VersionnumberTable_ban: [], //版本号table
    VersionnumberTablepageNum: 1,
    VersionnumberTablepageSize: 10,
    VersionnumberTabletotal: 0,

    Versionnumberloding: true, //版本号loding

    filesUrl: "",
    uploadiIagesList: [], //存放上传的文件
    isup: "add",
    //当前编辑的id
    editId: null,
    // 3个搜索框，
    _shebeiname: "",
    _gongyingshang: undefined,
    _shebeidalei: undefined,
    _viseblelookDIr: false, //查看章节目录
    __addvisibleModal: false, //添加章节目录的弹框
    visibleEcho: false, //第一个弹框 回显
    visibleEchoTWO: false, //第2个弹框 回显
    Installation_anzhuangbao: false, //第3个弹框 回显
    InputSearchValue: "", //输入框 第三个弹框
    value: undefined, //资源分类  有 无
    Zi_yuan_lei: [], //资源类型
    Zi_qi_dong: [{
      type: 1,
      desc: '引导'
    },{
      type: 2,
      desc: '考核 '
    },{
      type: 3,
      desc: '实训'
    }],//启动类型
    Xuanzhongqi: [],//选择启动类型
    Zi_yuan_chu: undefined, //资源类型初始追
    Gong_ying: [], //供应商
    Gong_ying_chu: undefined, //供应商初始值
    detailData: {}, //详情
    _chakan: null, // 详情ID
    runType: [], // 运行方式
    hardWareData: [], //硬件适配
    majorArr: [], //专业
    majorArrBig: [], //专业一级
    majorArrTwo: [], //专业二级
    dayNum: undefined,
    curSelectSoure: {},  //某一行数据
    addressPassword: {},  //地址和密码
    isOneOpen: true,
    teacherInfo: null, // 老师信息
    isSendNode: false,
    joinList: [], //对接级别
    majorData: {},
    __leftLOOKdirList: [],//查看章节目录的列表
    __add_Shang_ji_list: [], //章节目录的上级目录
    ___dataSourceTable: [],//编辑章节目录的右侧的表格
    ___dataSourceTableloading: true,//编辑章节目录的loding,
    isaddDirup: 'add',
    isshow: false,
    zhangjie: false,//章节目录
    resourceId: '1407666608281231362',
    yesDIR: false,
    addDIR_item: false,
    edit__leftLOOKdirList: [],
    uploadiIagesList_loding: false,//上传安装包的
    lookDirvisible: false,//查看章节目录的显示隐藏,
    __lookData: [],//查看章节目录的数据,
    yunxingfangshiexe: null,
    hunHeZiYuan: [],//混合资源
    hunHeZiYuanChushi: undefined,//混合资源初始值
    resourceType: 1,//资源类型
    isReport: 0 // 是否有实训报告

  };

  _prop_toog_addDIR_itemfn = () => {

    this.setState((state) => ({ addDIR_item: !state.addDIR_item }))

  }
  _prop_toog_yesDIR = () => {
    this.setState((state) => ({ yesDIR: !state.yesDIR }), this.get_edit__leftLOOKdirList)
  }
  lookDIr = () => {
    this.setState(() => ({
      lookDirvisible: true
    }))
  }

  get_edit__leftLOOKdirList = async () => {
    const { resourceId } = this.state
    const edit__leftLOOKdirList = await resourcechapterlist({ resourceId })
    this.setState(() => ({ edit__leftLOOKdirList }))
    return [...edit__leftLOOKdirList]
  }

  componentDidMount() {
    this.hardWare(); //硬件适配下拉
    this.getDara({ //d大表戈
      pageNum: this.state.pageNumon,
      pageSize: this.state.pageSizeon
    })
    this.runType(); // 运行方式列表
    this.ziyuan(); //类型资源下拉框
    // this.qidong();//启动类型
    this.getoptionlist(); //供应商
    this.queryMajor();
    resourceorgJointLevelList().then((res) => {
      this.setState({
        joinList: res,
      });
    });
    this.hunHeZiYuan()
  }

  // 版本号table

  getDara = async (pp) => {
    const _page = await Curriculumoptionlist(pp);

    this.setState(() => {
      return {
        dataSource: _page.data,
        dataSourceloading: false,
        pageSizeon: _page.pageSize,
        pageNumon: _page.pageNum,
        totalon: _page.total,
      };
    });
  };
  //学科级别
  // 共用上
  getoptionlist = async (pp) => {
    const _op_tion = await resourcesupplieroptionlist(pp);

    this.setState(() => {
      return {
        Gong_ying: _op_tion,
      };
    });
  };
  // 资源类型（包含混合类型）
  hunHeZiYuan = async (p) => {
    const hunHeZiYuan = await getAllResourceTypeEnumList(p);
    this.setState(() => {
      return {
        hunHeZiYuan: hunHeZiYuan,
      };
    });
  };

  // 资源类型
  ziyuan = async (p) => {
    const Zi_yuan_lei = await getResourceTypeEnumList(p);
    this.setState(() => {
      return {
        Zi_yuan_lei: Zi_yuan_lei,
      };
    });
  };
  // 启动类型
  // qidong = async () => {
  //
  //   const Qi_dong = await getStartTypeEnumList();
  //   console.log('lcl',Qi_dong)
  //
  //   this.setState(() => {
  //     return {
  //       Zi_qi_dong: Qi_dong
  //     }
  //
  //   })
  //
  // }
  queryMajor = () => {
    getEducationStageEnumList().then((res) => {
      this.setState({
        majorArr: res,
      });
    });
  };
  hardWare = () => {
    getResourceAdaptiveHardwareEnumList().then((res) => {
      this.setState({
        hardWareData: res,
      });
    });
  };
  runType = () => {
    getResourceRunModeEnumList().then((res) => {
      this.setState({
        runType: res,
      });
    });
  };
  // 删除Model

  deleteModal = (b) => {
    Modal.confirm({
      title: "删除提示",
      icon: null,
      content: (
          <div style={{ display: "flex" }}>
            <Yichang />
            &emsp;确定要删除吗?
          </div>
      ),
      okText: "确认",
      cancelText: "取消",
      className: " deleteconfim",
      onCancel: this.ModalconfirmonCancel,
      onOk: () => {
        this.ModalconfirmononOk(b);
      },
    });
  };
  // 资源分类
  Radio_onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    if (e.target.value == 1 && this.state.editId == "edit") {
      this.setState({
        majorData: {
          resourceEducationLevel: undefined,
          resourceMajorFirst: undefined,
          resourceMajorSecond: undefined,
        },
      });
    }
  };
  // 实训报告
  Shix_onChange = (e) => {
    this.setState({
      isReport: e.target.value,
    });
  }


  // 添加
  addshowModal = (b) => {
    if (this.state.isup === "add") {
      this.setState(() => {
        return {
          addvisible: true,
        };
      });
    } else if (this.state.isup === "edit") {
      this.setState(
          () => {
            return {
              addvisible: true,
            };
          },
          () => {
            Curriculumopdetail({ resourceId: b.resourceId }).then((res) => {
              if (res && res.isChapter + '') {
                this.setState({
                  value: res.isChapter === 1 ? 1 : 0
                })
                // sss
              }
              const arr = this.state.uploadiIagesList;
              arr.push({
                resourceLoadOriginName: res.resourceLoadOriginName,
                resourceLoadOriginType: res.resourceLoadOriginType,
                resourceLoadUrl: res.resourceLoadUrl,
                resourceSize: res.resourceSize,

              });
              this.setState(
                  {
                    isshow: true,
                    isTrue: res.resourceRunMode,
                    uploadiIagesList: arr,
                  }
              );
              this.props.form.setFieldsValue({
                livePeriod6665: "123",
              });
              // }
              if (res) {
                if (res.resourceEducationLevel) {
                  const _res = res;
                  getEducationStageEnumList().then((res) => {


                    this.setState({
                      stageId: _res.resourceEducationLevel,
                      majorArr: res,
                    });
                  });
                }
                if (res.resourceMajorFirst) {
                  eduSubList({
                    stageId: res.resourceEducationLevel,
                    parentId: 0,
                  }).then((res) => {

                    this.setState({
                      majorArrBig: res,
                    });
                  });
                }
                if (res.resourceMajorSecond) {
                  eduSubList({
                    stageId: res.resourceEducationLevel,
                    parentId: res.resourceMajorFirst,
                  }).then((res) => {


                    this.setState({
                      majorArrTwo: res,
                    });
                  });
                }
                const hotArr = [];
                if (res.hot) {
                  res.hot.forEach((v) => {
                    hotArr.push(Number(v.hotId));
                  });
                }
                const startTypes = [];
                if (res.startTypes) {
                  res.startTypes.forEach((v) => {
                    startTypes.push(Number(v.startType));
                  });
                }

                if (res.resourceEducationLevel) {
                  this.setState({
                    majorData: {
                      resourceEducationLevel: res.resourceEducationLevel,
                      resourceMajorFirst: res.resourceMajorFirst,
                      resourceMajorSecond: res.resourceMajorSecond,
                    },
                  });
                }
                this.props.form.setFieldsValue({
                  //   ...res,
                  resourceName: res.resourceName,
                  resourceType: res.resourceType
                      ? res.resourceType.toString()
                      : undefined,
                  jointLevel: res.jointLevel,
                  supplierId: res.supplierId
                      ? res.supplierId.toString()
                      : undefined,
                  resourceRunMode: res.resourceRunMode
                      ? res.resourceRunMode.toString()
                      : undefined,
                  defaultInstallPath: res.defaultInstallPath,
                  slientInstallParameter: res.slientInstallParameter,
                  resourceRange: res.resourceRange,
                  resourceExplain: res.resourceExplain,
                  resourceLoadUrl: res.resourceLoadUrl,
                  livePeriod3: res.resourceMajorFirst ? 2 : 1,
                  hotIds: hotArr,
                  appName: res.appName,
                  startTypes: startTypes,
                  webUrl: res.webUrl,
                  isChapter: res.isChapter === 1 ? 1 : 0,
                  resourceAdaptiveHardware: res.resourceAdaptiveHardware,
                  resourceVersion: res.resourceVersion,
                  resourceRegistryKey: res.resourceRegistryKey,
                  resourceLoadOriginName: res.resourceLoadOriginName,
                  resourceImg: res.resourceImg,
                  isReport: res.isReport
                  // jointLevel: "1",
                });
              }
            })
          })
    }
  }
  // // 弹框 设备类别
  categoryadd_onChange(value) {
    console.log(`selected ${value}`);
  }
  // 资源链接第一个弹框
  zi_onChange = (value) => {
    this.setState({
      dayNum: value,
    })
  }
  // 新增弹框
  // 专业本科
  btn_onChange = (value) => {
    this.props.form.setFieldsValue({
      resourceMajorSecond: undefined,
      resourceMajorFirst: undefined,
    });
    this.setState({
      stageId: value,
    });
    eduSubList({
      stageId: value,
      parentId: 0,
    }).then((res) => {
      // console.log(res);
    });
  }
  addhandleOk = (e) => {

    this.props.form.setFieldsValue({ 'livePeriod6665': this.state.uploadiIagesList })

    if (this.state.isup === "add") {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.state.uploadiIagesList.length < 1 && values.resourceRunMode === '2') {
            return this.mesWarning('请上传安装包')
          }

          const o = {
            ...values,
            resourceLoadOriginName: this.state.uploadiIagesList[0]?.originFileName,
            resourceLoadOriginType: this.state.uploadiIagesList[0]?.mediaType,
            resourceLoadUrl: this.state.uploadiIagesList[0]?.fpName,
            resourceSize: this.state.uploadiIagesList[0]?.fileSize,
          }

          createCurriculumop(o).then((res) => {
            this.mesSuccess("添加成功");
            this.setState({
              addvisible: false,
              uploadiIagesList: [],
              isshow: false,


            });
            this.getDara();
            return {
              values, res
            }
          }).then(res => {
            // if (res.values.isChapter === 1) {
            this.setState(() => ({
              resourceId: res.res.resourceId
            }), () => {
              if (this.state.value === 1) {
                this.addhandleCancel();
                this._prop_toog_yesDIR()


              }

              setTimeout(() => {
                this.setState({
                  value: undefined
                })
              })


            })

            // }
          });
        }
      });
      // 编辑
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const { uploadiIagesList } = this.state
          editCurriculumop({
            ...values,
            isChapter: values.isChapter === 1 ? 1 : 0,
            resourceId: this.state.resourceId,
            resourceEducationLevel:
                this.state.value == 1 ? null : values.resourceEducationLevel,
            resourceMajorFirst:
                this.state.value == 1 ? null : values.resourceMajorFirst,
            resourceMajorSecond:
                this.state.value == 1 ? null : values.resourceMajorSecond,
            resourceLoadOriginName: uploadiIagesList[0]?.resourceLoadOriginName || uploadiIagesList[0]?.originFileName,
            resourceLoadOriginType: uploadiIagesList[0]?.resourceLoadOriginType || uploadiIagesList[0]?.mediaType,
            resourceLoadUrl: uploadiIagesList[0]?.resourceLoadUrl || uploadiIagesList[0]?.fpName,
            resourceSize: uploadiIagesList[0]?.resourceSize || uploadiIagesList[0]?.fileSize,

          }).then(() => {

            this.mesSuccess("编辑成功");
            this.setState(() => {
              return {
                majorData: {
                  resourceEducationLevel: undefined,
                  resourceMajorFirst: undefined,
                  resourceMajorSecond: undefined,
                },
                addvisible: false,
                uploadiIagesList: [],

              };
            });
            this.getDara();
          });
        }
      });
    }
  };

  addhandleCancel = (e) => {
    this.setState(() => ({
      majorData: {},
      addvisible: false,
      uploadiIagesList: [],
      isTrue: null,
      isshow: false,
      value: undefined
    }));
  };
  // 操作记录
  operationModal = () => {

    this.getDataList({
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    })
    this.setState({
      operation: true,
    });
  };

  operationOk = (e) => {

    this.setState({
      operation: false,
    });
  };

  operationCancel = (e) => {

    this.setState({
      operation: false,
    });
  };
  // 版本号

  VersionnumberModal = () => {
    this.setState({
      Versionnumber: true,
    });
  };

  VersionnumberOk = (e) => {

    this.setState({
      Versionnumber: false,
    });
  };

  VersionnumberCancel = (e) => {

    this.setState({
      Versionnumber: false,
    });
  };
  // 查看抽屉
  showDrawer = async (b) => {

    // const d=await Curriculumopdetail({resourceId:v.resourceId})
    this.setState({
      visible: true,
      _chakan: b.resourceId,
    });
    Curriculumopdetail({ resourceId: b.resourceId }).then((res) => {

      this.setState({
        detailData: res,
        resourceType: res.resourceType
      });
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  // 输入筛选
  headerSearch = (target) => {
    const { name, value } = target;

    this.setState(() => {
      return {
        [name]: value,
      };
    });
  };
  headerSearch_gongyingshang = (item) => {
    this.setState(() => {
      return {
        Zi_yuan_chu: item,
      };
    });
  };
  headerSearch_gongyingshang_hunhe = (item) => {
    this.setState(() => {
      return {
        hunHeZiYuanChushi: item,
      };
    });
  }
  headerSearch_shebeidalei = (item) => {
    this.setState(() => {
      return {
        Gong_ying_chu: item,
      };
    });
  };

  //搜索重置按钮
  searchVlueReset = () => {
    this.setState(
        () => {
          return {
            hunHeZiYuanChushi: undefined,
            _shebeidalei: undefined,

            _shebeiname: undefined,
            Gong_ying_chu: undefined,
          };
        },
        () => {
          this.getDara();
        }
    );
  };
  //查询按钮
  searchVlueSearch = () => {
    const { hunHeZiYuanChushi, _shebeidalei, _shebeiname, Gong_ying_chu, pageSizeon, pageNumon } =
        this.state;

    this.getDara({
      pageSize: pageSizeon,
      pageNum: pageNumon,
      resourceName: _shebeiname,
      supplierId: Gong_ying_chu,
      resourceType: hunHeZiYuanChushi,
    });
  };

  // 供应商
  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  // 设备大类
  thunder_onChange = (value) => {
    console.log(`selected ${value}`);
  };

  // 设备类别category
  category_onChange = (value) => {
    console.log(`selected ${value}`);
  };

  // 弹框 资源类型

  supplier_onChange = (value) => {
    this.setState({
      resourceType: value,
    });
    console.log(`selected ${value}`);
  }

  // 弹框 对接级别
  Equipmentadd_onChange = async (value) => {
    const { detailData: { resourceId } } = this.state

    if (value === '1') {
      this.props.form.setFieldsValue({ 'parentId': '0' })
    }
    const _data = await resourcechapteroptionList({
      resourceChapterLevel: value,
      resourceId
    })
    this.setState(() => {
      return {
        __add_Shang_ji_list: _data
      }
    })

  }
  //   // 弹框 提供商
  ti_onChange(value) {
    console.log(`selected ${value}`);
  }

  //  弹框 运行方式
  yun_onChange = (value) => {

    this.setState(() => {
      return {
        isTrue: value,
        isshow: true
      }
    });
  };
  // 弹框 对接级别
  Ji_onChange(value) {
    console.log(`selected ${value}`);
  }
  // 弹框 适配硬件
  shi_onChange(value) {
    console.log(`selected ${value}`);
  }

  // // 弹框 设备类别
  categoryadd_onChange(value) {
    console.log(`selected ${value}`);
  }
  // 资源链接第一个弹框
  zi_onChange(value) {
    console.log(`selected ${value}`);
  }
  // 新增弹框
  // 专业本科
  btn_onChange = (value) => {
    this.props.form.setFieldsValue({
      resourceMajorSecond: undefined,
      resourceMajorFirst: undefined,
    });
    this.setState({
      stageId: value,
    });
    eduSubList({
      stageId: value,
      parentId: 0,
    }).then((res) => {
      // console.log(res);
      this.setState({
        majorArrBig: res,
      });
    });
    console.log(`selected ${value}`);
  };
  // 专业大类
  city_onChange = (value) => {
    this.setState(
        {
          majorArrTwo: undefined,
        },
        () => {
          // console.log(this.state.stageId);
          eduSubList({
            stageId: this.state.stageId,
            parentId: value,
          }).then((res) => {
            // console.log(res);
            this.setState({
              majorArrTwo: res,
            });
          });
        }
    );
    this.props.form.setFieldsValue({
      resourceMajorSecond: undefined,
    });
    console.log(`selected ${value}`);
  };
  // 专业2类
  area_onChange = (value) => {
    console.log(`selected ${value}`);
  };


  down = ({ resourceLoadUrl, resourceLoadOriginName }) => {
    saveAs(window.$$imgSrc(resourceLoadUrl), resourceLoadOriginName)
  }

  ModalconfirmonCancel = () => { };
  ModalconfirmononOk = (record) => {

    // console.log(record);
    removeCurriculumop({
      resourceId: record.resourceId,

    }).then((res) => {

      // console.log(res);
      this.mesSuccess("删除成功");
      this.searchVlueSearch()
    });
  };
  //章节目录删除
  deleteSiginDir = () => {
    Modal.confirm({
      title: "删除提示",
      icon: null,
      content: (
          <div style={{ display: "flex" }}>
            <Yichang />
            &emsp;资源目录已被使用，不可删除
          </div>
      ),
      okText: "确认",
      cancelText: "取消",
      className: " deleteconfim",
      onCancel: this.ModalconfirmonCancel,
      onOk: this.ModalconfirmononOk,
    });
  };






  //第一个弹框 回显
  visibleEchoModal = (record) => {

    getValidTimeEnumList().then((res) => {
      this.setState({ validity: res });
    });
    this.setState({
      visibleEcho: true,
      curSelectSoure: record,
      // Installation_anzhuangbao: true
    });
  };


  visibleEchoCancel = (e) => {
    // console.log(e);
    this.setState({
      visibleEcho: false,
    });
  };
  //第E2个弹框 回显
  visibleEchoTWOModal = async () => {
    const { dayNum, curSelectSoure } = this.state;
    const res = await createAddr({
      resourceId: curSelectSoure.resourceId,
      validTime: dayNum,
    })

    this.setState({
      visibleEchoTWO: true,
      visibleEcho: false,
      addressPassword: res || {}
    });
  };


  visibleEchoTWOCancel = (e) => {
    // console.log(e);
    this.setState({
      visibleEchoTWO: false,
    });
  };



  // 第3个弹框  安装包
  Installation_anzhuangbaoModal = () => {
    this.setState({
      visibleEchoTWO: false,
      Installation_anzhuangbao: true,
    });
  };

  Installation_anzhuangbaoCancel = (e) => {
    // console.log(e);
    this.setState({
      Installation_anzhuangbao: false,
    });
  };

  depCloneTree = (tree) => {

    let arrs = [];
    let result = [];
    arrs = arrs.concat(JSON.parse(JSON.stringify(tree)));
    while (arrs.length) {
      let first = arrs.shift();
      if (first.children) {

        arrs = arrs.concat(first.children);
        delete first["children"];
      }
      result.push(first);
    }
    return result;

  }
  setRightData = (v) => {
    const arr = this.depCloneTree(v)
    this.setState(() => {
      return {
        ___dataSourceTable: arr,
        ___dataSourceTableloading: false
      }
    })
  }
  //修改
  xiugai11 = async (v) => {

    this.props.form.setFieldsValue({ ...JSON.parse(JSON.stringify(v)) })
    this.setState(() => {
      return {
        isaddDirup: 'edit',
        __addvisibleModal: true
      }
    })
  }
  chuab = (v) => {
    this.props.history.push(
        `/main/PracticalTraining/CurriculumXiang?resourceId=${v.resourceId}`
    );

  }
  Installation_anzhuangbaoOk = async () => {
    const { dayNum, addressPassword, isSendNode, teacherInfo, curSelectSoure } = this.state;
    if (!teacherInfo) {
      this.mesWarning('没有老师信息');
      return;
    }
    await resourcedownloadSend({
      resourceName: curSelectSoure.resourceName,
      downloadPageUrl: addressPassword.downloadPageUrl,
      password: addressPassword.password,
      validTime: dayNum,
      resourceRunMode: curSelectSoure.resourceRunMode,
      resourceDownloadId: addressPassword.resourceDownloadId,
      receiveUserId: teacherInfo.userId,
      receiveUserName: teacherInfo.userName,
      receiveUserPhone: teacherInfo.telNum,
      receiveUserOrg: teacherInfo.enterpriseId,
      receiveUserJob: teacherInfo.positions,
      isSendNode: isSendNode ? 2 : 1,
    });
    this.setState({
      Installation_anzhuangbao: false,
    });
    this.mesSuccess('提交成功');


  };

  handlerEditDir = () => {

    this._prop_toog_yesDIR()
  }

  // 复选框
  onChange_check = (e) => {
    this.setState({
      isSendNode: e.target.checked
    })
  }
  //搜索框
  searchVlueChange = (e) => {
    const val = e.target.value;
    this.setState(() => {
      return {
        InputSearchValue: val,
      };
      //   uploadiIagesList: [],
    });
  }

  //回车搜索
  searchEnter = async () => {
    const { InputSearchValue } = this.state;
    //调接口
    const res = await getUserInfoByTel({
      telNum: InputSearchValue
    });
    //返回值
    if (res?.code === 0) {
      this.setState(() => {
        return {
          isOneOpen: false,
          teacherInfo: null,
        }
      })
    } else {
      this.setState(() => {
        return {
          isOneOpen: false,
          teacherInfo: res,
        }
      })
    }
  };

  // 操作记录
  getDataList = (pp) => {
    return pageListdara({
      operateType: 6,
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize
    }).then(res => {
      if (res && res.data) {
        this.setState(() => {
          return {
            dataSourceTable: res.data,
            total: res.total,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            dataSourceloading: false
          }
        })
      } else {
        message.info('数据错误')
      }
    })
  }
  //pageSize变化
  onShowSizeChange = (current, pageSize) => {
    // console.log(current, pageSize, ":sdd")
    this.setState({
          pageNum: current,
          pageSize: pageSize,
        }, () => {
          this.getDataList({
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum
          });
        }
    );
  };

  //pageNum变化
  onPageNumChange = (current, pageSize) => {
    // console.log(current, pageSize, ":sdd")
    this.setState({
          pageNum: current,
        }, () => {
          this.getDataList({
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum
          });
        }
    );
  }
  // 启动方式
  // onChange_qi = (e) => {
  //   console.log(`checked = ddddddddddddddd${e.target.value}`);
  //   this.setState(()=>{

  //     return {
  //       Xuanzhongqi:e.target.value
  //     }
  //   })
  // }

  handleImgChange = (fileId, mediaType) => {
    this.props.form.setFieldsValue({"resourceImg": fileId})
    this.setState({
      fileId,
      mediaType
    })
  }

  //pageSize变化
  sizeChange = (current, pageSize) => {
    this.setState({
          pageNumon: current,
          pageSizeon: pageSize,
        }, () => {
      this.searchVlueSearch();
        }
    );
  };

  //pageNum变化
  pageChange = (current, pageSize) => {
    this.setState({
          pageNumon: current,
        }, () => {
      this.searchVlueSearch();
        }
    );

  };

  shouquan = () => {
    return this.mesWarning('请先完善资源信息')
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { dataSource, dataSourceTable, VersionnumberTable_ban} =
        this.state;
    const { RenderButton } = React
    // const RenderButton = (p)=>p.children
    const formItemLayout_aaaa = {
      labelCol: {
        xs: { span: 2 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 2 },
        sm: { span: 12 },
      },
    };

    const uploadPwdDic = {
      showUploadList: false,
      beforeUpload: async (file) => {
        this.setState(() => ({
          uploadiIagesList_loding: true
        }))
        try {
          const fromdata = new FormData();
          fromdata.append("file", file);
          fromdata.append("folder", "ab");

          uploadFile(fromdata).then((res) => {
            // console.log(res);
            if (res) {
              this.props.form.setFieldsValue({ 'livePeriod6665': [res] })
              this.mesSuccess('上传安装包成功')

              this.setState(() => ({ uploadiIagesList: [res], uploadiIagesList_loding: false }));
              // console.log(this.state.uploadiIagesList, 'uploadiIagesList')
            } else {
              this.mesWarning('上传安装包失败')
            }
          });
        } catch (err) {
          this.mesWarning('上传安装包失败')

        }
        return false
      }
    }

    const plainOptions = [
      { value: 1, label: "汽车制造" },
      { value: 2, label: "汽车维修" },
      { value: 3, label: "人工智能" },
      { value: 4, label: "其他" },
    ];

    //编辑章节目录的表格clums

    // Table
    const columns = [
      { width: 130, title: "资源名称", dataIndex: "resourceName" },
      {
        width: 130,
        title: "资源类型",
        dataIndex: "resourceTypeDesc",

      },
      { width: 130, title: "提供商", dataIndex: "supplierName" },
      { width: 130, title: "运行方式", dataIndex: "resourceRunModeDesc" },
      {
        width: 130,
        title: "适配硬件",
        dataIndex: "resourceAdaptiveHardwareDesc",
      },
      {
        width: 130,
        title: "章节目录",
        dataIndex: "isChapter",
        render: (v, b) => {
          return (
              <div>
                {b.isChapter === 0 ? <span>否</span> : <span>是</span>}
              </div>
          );
        },
      },
      {
        width: 90,
        title: "报告",
        dataIndex: "isReport",
        render: (v, b) => {
          return (
              <div>
                {b.isReport === 0 ? <span>否</span> : <span>是</span>}
              </div>
          );
        },
      },
      {
        width: 90,
        title: "版本号",
        dataIndex: "resourceVersion",
        render: (v, b) => {
          return (
              <div
                  onClick={async () => {
                    const _ban = await listByResourceId({
                      resourceId: b.resourceId, //类型：String  可有字段  备注：资源ID
                      pageNum: 0, //类型：Number  必有字段  备注：当前页
                      pageSize: 15, //类型：Number  必有字段  备注：每页记录数
                    });
                    this.setState(
                        () => {
                          return {
                            VersionnumberTable_ban: _ban.data,
                            VersionnumberTablepageNum: _ban.pageNum,
                            VersionnumberTablepageSize: _ban.pageSize,
                            VersionnumberTabletotal: _ban.total,
                            Versionnumberloding: false,
                          };
                        },
                        () => {
                          this.VersionnumberModal();
                        }
                    );
                  }}
                  className="underline"
              >
                {v}
              </div>
          );
        },
      },
      { width: 150, title: "更新时间", dataIndex: "updateTime" },
      {
        width: 400,
        title: "操作",
        fixed: "right",
        render: (v, b, value) => {

          // console.log(v, b, 'vvvbbb')
          return (
              <div className="action vertical_j" style={{ cursor: "pointer" }}>
                <RenderButton id="ABB-01-B02-b_btnViewResource">   <span onClick={() => this.showDrawer(b)}>资源详情</span></RenderButton>
                <RenderButton id="ABB-01-B02-b_btnUsingDetail">
                <span
                    onClick={() => {
                      this.chuab(v)
                    }}
                >
                  使用详情
                </span>
                </RenderButton>
                <RenderButton id="ABB-01-B02-b_btnResourcesAuthorization">
                  {b.jointLevel === null? <span onClick={() => {
                    this.shouquan()
                  }}>资源授权</span> : <span
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/main/PracticalTraining/Authorization",
                          query: {
                            resourceId: b.resourceId,
                          },
                        });
                        sessionStorage.setItem("resourceId", b.resourceId);
                      }}
                  >
                  资源授权
                </span>}
                </RenderButton>
                <RenderButton id="ABB-01-B02-b_btnEdit">
                <span
                    onClick={() => {

                      this.setState(
                          () => {
                            return {
                              isup: "edit",
                              //存储当前行的id,
                              resourceId: b.resourceId
                            };
                          },
                          () => {
                            this.addshowModal(b);
                          }
                      );
                    }}
                >
                  编辑
                </span>
                </RenderButton>
                <RenderButton id="ABB-01-B02-b_btnDel">
                <span
                    onClick={() => {
                      this.deleteModal(b);
                    }}
                >
                  删除
                </span>
                </RenderButton>


                {/*//考虑学校就算新采买机器，通过安装包的方式安装，但实际也需要调整的东西很多，所以实际商无意义，所以注掉该按钮*/}
                {/*{*/}
                {/*  b.resourceRunMode === 2 ?*/}
                {/*      // exe*/}
                {/*      <RenderButton id="ABB-01-B02-b_btnCreateLinks">*/}
                {/*    <span onClick={() => {*/}
                {/*      this.visibleEchoModal(b)*/}
                {/*    }}>创建链接</span></RenderButton> : null*/}

                {/*}*/}



              </div>
          );
        },
        align: "center",
      },
    ];
    columns.forEach((v) => (v.align = "center"));

    const columnsTable = [
      { title: "操作人", dataIndex: "account", key: "name" },
      { title: "课程资源管理", dataIndex: "operationDesc", key: "age" },
      { title: "操作时间", dataIndex: "createTime", key: "address" },
      { title: "动作", dataIndex: "actionDscp", key: "address" },
    ];
    columnsTable.forEach((v) => (v.align = "center"));

    const Versionnumbercolunms = [
      { title: "版本号", dataIndex: "versionName" },
      { title: "操作人", dataIndex: "createUserName" },
      { title: "操作时间", dataIndex: "createTime" },
      {
        title: "动作",
        dataIndex: "action",

      },
    ];
    Versionnumbercolunms.forEach((v) => (v.align = "center"));
    const {
      detailData,
      majorArr,
      isTrue,
      majorArrTwo,
      majorArrBig,
      joinList,
      majorData,
    } = this.state;
    return (
        <div className="Curriculum_model_management">
          <div className="Curriculum_model_management_top">
            <div>
              <Input
                  placeholder="资源名称"
                  name="_shebeiname"
                  onChange={(e) => this.headerSearch(e.target)}
                  value={this.state._shebeiname}
                  style={{ width: 200 }}
              />
              <Select
                  style={{ width: 200, marginLeft: 30 }}
                  placeholder="资源类型"
                  onChange={this.headerSearch_gongyingshang_hunhe}
                  value={this.state.hunHeZiYuanChushi}
              >
                {this.state.hunHeZiYuan
                    ? this.state.hunHeZiYuan.map((item, index) => {
                      return <Option key={item.type}>{item.desc}</Option>;
                    })
                    : null}
              </Select>
              <Select
                  style={{ width: 200, marginLeft: 30 }}
                  placeholder="提供商"
                  onChange={this.headerSearch_shebeidalei}
                  value={this.state.Gong_ying_chu}
              >
                {this.state.Gong_ying
                    ? this.state.Gong_ying.map((item, index) => {
                      return (
                          <Option key={item.supplierId}>{item.supplierName}</Option>
                      );
                    })
                    : null}
              </Select>
            </div>
            <div>
              <Button
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    this.searchVlueReset();
                  }}
              >
                重置
              </Button>
              <Button
                  type="primary"
                  onClick={() => {
                    this.searchVlueSearch();
                  }}
              >
                查询
              </Button>
            </div>
          </div>
          <div className="Curriculum_model_management_main">
            <RenderButton id="ABB-01-B02-b_btnAdd">
              <Button
                  type="primary"
                  onClick={() => {
                    this.setState(
                        () => {
                          return {
                            isup: "add",
                          };
                        },
                        () => {
                          this.addshowModal();
                        }
                    );
                  }}
              >
                添加
              </Button>
            </RenderButton>
            <RenderButton id="ABB-01-B02-b_btnOperatingRecord">
              <Button onClick={this.operationModal}>操作记录</Button></RenderButton>
          </div>
          <div className="Curriculum_model_management_main">
            <Table
                dataSource={dataSource}
                rowKey='resourceId'
                columns={columns}
                pagination={
                  {
                    defaultCurrent: 1,
                    pageSizeOptions: ['10', '20', '30'],
                    showSizeChanger: true,
                    onShowSizeChange: (a, b) => this.sizeChange(a, b),
                    onChange: (a, b) => this.pageChange(a, b),
                    current: this.state.pageNumon,
                    pageSize: this.state.pageSizeon,
                    total: this.state.totalon,
                  }
                }
                loading={this.state.dataSourceloading}
                scroll={{  x: "calc(100% - 700px)" }}
            />
          </div>
          <div>
            <Drawer
                title="资源信息"
                placement="right"
                closable={false}
                // onClose={this.onClose}
                visible={this.state.visible}
                width={600}
                className="information_xin"
            >
              <p>
                <span>资源名称：</span>
                <span>{detailData.resourceName}</span>
              </p>
              <p>
                <span>资源类型 ：</span>
                <span>{detailData.resourceTypeDesc}</span>
              </p>
              <p>
                <span>对接级别 ：</span>
                <span>{detailData.jointLevelDesc}</span>
              </p>
              <p>
                <span>资源分类：</span>
                <span>{detailData?.resourceEducationLevelDesc}&nbsp;&nbsp;{detailData?.resourceMajorFirstName}&nbsp;&nbsp;{detailData?.resourceMajorSecondName}</span>
              </p>
              <p>
                <span>热门方向：</span>
                <span>{this.__isArray(detailData?.hot) && detailData?.hot.map((pp, index) => {
                  return <span key={index}>{pp.hotName}&emsp;{pp < detailData.length?.length - 1 ? <>{'-'}&emsp;</> : null}</span>
                })}</span>
              </p>


              <p>
                <span>提供商：</span>
                <span>{detailData.supplierName}</span>
              </p>
              <p>
                <span>运行方式：</span>
                <span>{detailData.resourceRunModeDesc}</span>
              </p>
              {detailData.resourceRunMode === 1 ?
                  <p style={{ display: "flex" }}>
                    <span>网址链接：</span>
                    <span className="parameters_nobacnk">{detailData.webUrl}</span>
                  </p>
                  : ''
              }
              {
                detailData.resourceRunMode === 1 ? null : <p style={{ display: "flex" }}>
                  <span>安装路径：</span>
                  <span className="parameters_transparent">
                  <span>{detailData.defaultInstallPath}</span>
                </span>

                </p>
              }
              {
                detailData.resourceRunMode === 1 ? null : <p style={{ display: "flex" }}>
                  <span>安装参数：</span>
                  <span className="parameters_transparent">
                  <span>{detailData.slientInstallParameter}</span>
                </span>

                </p>
              }
              {
                detailData.resourceRunMode === 1 ? null : <p style={{ display: "flex" }}>
                  <span>注册码：</span>
                  <span className="parameters_transparent">
                  <span>{detailData.resourceRegistryKey}</span>
                </span>

                </p>
              }

              {
                detailData.resourceRunMode === 1 ? null : <p>
                  <span>版本号：</span>
                  <span>{detailData.resourceVersion}</span>
                </p>
              }


              <p>
                <span>适配硬件：</span>
                <span>{detailData.resourceAdaptiveHardwareDesc}</span>
              </p>

              <p style={{ display: "flex" }}>
                <span>适用场景：</span>
                <span className="parameters">
                <span>{detailData.resourceRange}</span>
              </span>
              </p>
              {
                detailData.resourceRunMode === 1 ? null :
                    <p style={{ display: "flex" }}>
                  <span className="parameters_ff">
                    <span>上传安装包:</span>
                  </span>
                      <span
                          onClick={() => {
                            this.down(detailData || {});
                          }}
                          style={{ display: "flex", cursor: "pointer", marginTop: 14 }}
                      >
                    <Word />
                        &emsp;<span>
                      {detailData.resourceLoadOriginName && detailData.resourceLoadOriginName.length > 15 ? detailData.resourceLoadOriginName.substring(0, 15) + '...' : detailData.resourceLoadOriginName}
                    </span>
                    <span>
                      &nbsp;
                      <Xiazai />
                    </span>
                        &nbsp;<span>下载</span>
                  </span>
                    </p>
              }
              <p style={{ display: "flex" }}>
                <span>备注说明：</span>
                <span className="parameters">
                <span>{detailData.resourceExplain}</span>
              </span>
              </p>

              {
                detailData.isChapter === 0 ? null :
                    <p>
                      <span>章节目录：</span>
                      <span>
                    <Button
                        style={{ border: "1px solid #3E78ED", color: "#3E78ED" }}
                        onClick={async () => {

                          const _data = await resourcechapterlist({ resourceId: detailData.resourceId })
                          //
                          this.setState(() => {
                            return {
                              __lookData: _data,
                            }
                          }, () => this.lookDIr())


                        }}
                    >
                      查看章节目录
                </Button>
                  </span>
                    </p>
              }



              <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderTop: "1px solid #e8e8e8",
                    padding: "10px 16px",
                    textAlign: "right",
                    left: 0,
                    background: "#fff",
                    borderRadius: "0 0 4px 4px",
                  }}
              >
                <Button
                    onClick={this.onClose}
                    type="primary"
                    style={{
                      width: 90,
                      height: 40,
                      position: "relative",
                      right: 400,
                    }}
                >
                  我知道了
                </Button>
              </div>
            </Drawer>
          </div>
          {/* 操作记录 */}
          <Modal
              title={
                <div style={{ fontWeight: 'bold', color: "#333", fontSize: 18 }}>
                  操作记录
                </div>
              }
              visible={this.state.operation}
              onCancel={this.operationCancel}
              bodyStyle={{ height: 500 }}
              width={1000}
              centered
              closable={false}
              wrapClassName="details_record"
              footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={this.operationOk}
                    style={{ width: 90, height: 40 }}
                >
                  我知道了
                </Button>,
              ]}
          >
            <div>
              {/* OOOOOOOOOOOOOOOOOO */}
              <Table
                  dataSource={dataSourceTable}
                  columns={columnsTable}
                  loading={this.state.dataSourceloading}
                  scroll={{ y: 350 }}
                  pagination={
                    {
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30'],
                      showSizeChanger: true,
                      onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                      onChange: (a, b) => this.onPageNumChange(a, b),
                      current: this.state.pageNum,
                      pageSize: this.state.pageSize,
                      total: this.state.total,
                    }
                  }
              />
              ;
            </div>
          </Modal>
          {/* 版本号 */}

          <Modal
              title={
                <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
                  版本号
                </div>
              }
              visible={this.state.Versionnumber}
              onCancel={this.VersionnumberCancel}
              width={1000}
              centered
              closable={false}
              footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={this.VersionnumberOk}
                    style={{ width: 90, height: 40 }}
                >
                  我知道了
                </Button>,
              ]}
          >
            <div>
              <Table
                  dataSource={VersionnumberTable_ban}
                  columns={Versionnumbercolunms}
                  pagination={{
                    defaultCurrent: 1,
                    pageSizeOptions: ["10", "20", "30"],
                    showSizeChanger: true,
                  }}
                  loading={this.state.Versionnumberloding}
                  scroll={{ y: 200 }}
              />

            </div>
          </Modal>
          {/* 添加 */}
          <div>
            <Modal
                title={
                  <div style={{ fontSize: 18, color: "#333", fontWeight: 500 }}>
                    {this.state.isup === "add"
                        ? "添加资源"

                        : "修改资源"}

                  </div>
                }
                key={this.state.addvisible}
                visible={this.state.addvisible}
                onOk={this.addhandleOk}
                onCancel={this.addhandleCancel}

                // bodyStyle={{ height: !this.state.isshow ? 500 : (this.props.form.getFieldValue('resourceRunMode') === '2') ? 1450 : 940 }}
                bodyStyle={{
                  height: 360,
                  overflow: 'auto'
                }}

                width={900}
                centered
                centered={true}
                destroyOnClose
                wrapClassName="Device_information_curri"
            >
              <Form {...formItemLayout_aaaa}>
                <Form.Item label="资源名称">
                  {getFieldDecorator("resourceName", {
                    rules: [{ required: true, message: "请输入资源名称" }],
                  })(
                      <MaxLengthInput
                          placeholder="请输入资源名称"
                          style={{ height: 40, paddingRight: 60 }}
                          maxLength={30}
                      ></MaxLengthInput>
                  )}
                </Form.Item>

                <Form.Item label="对接级别">
                  {getFieldDecorator("jointLevel", {
                    rules: [{ required: true, message: "请选择对接级别" }],
                  })(
                      <Select
                          placeholder="请选择对接级别"
                          onChange={this.Ji_onChange}
                      >
                        {joinList
                            ? joinList.map((i, index) => {
                              return <Option key={index} value={i.type}>{i.desc}</Option>;
                            })
                            : null}
                      </Select>
                  )}
                </Form.Item>

                <Form.Item label="上传封面图">
                  <Row>
                    <Col span={5} style={{width: '195px'}}>
                      <div className='uploadImgWapper'>
                        {
                          getFieldDecorator('resourceImg', {
                            rules: [{ required: true, message: "请选择上传封面图" }],
                            initialValue: ''
                          })(<UploadImg
                              systemType='ts'
                              handleImgChange={this.handleImgChange}
                              html='上传封面图'/>)
                        }
                      </div>
                    </Col>
                    <Col span={13}>
                      <div className='img_title'>
                        <div className='tishi'>支持jpg/gif/png/jpeg格式，</div>
                        <div className='tishi'>建议最佳尺寸840*417px，不超过5M</div>
                      </div>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="实训报告">
                  {getFieldDecorator("isReport", {
                    rules: [{ required: true, message: "请选择实训报告" }],
                    initialValue: 0
                  })(
                      <Radio.Group
                          onChange={this.Shix_onChange}
                      >
                        <Radio value={0}>无</Radio>
                        <Radio value={1}>有</Radio>
                      </Radio.Group>
                  )}
                </Form.Item>

                <Form.Item label="资源分类">
                  {getFieldDecorator("livePeriod3", {
                    rules: [{ required: false, message: "请选择资源分类" }],
                    initialValue: 1
                  })(
                      <Radio.Group
                          onChange={this.Radio_onChange} //value={this.state.value}
                      >
                        <Radio value={1}>无</Radio>
                        <Radio value={2}>有</Radio>
                      </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item label="资源类型">
                  {getFieldDecorator("resourceType", {
                    rules: [{ required: true, message: "请选择资源类型" }],
                  })(
                      <Select
                          placeholder="请选择资源类型"
                          onChange={this.supplier_onChange}
                          allowClear={true}
                      >
                        {this.state.Zi_yuan_lei
                            ? this.state.Zi_yuan_lei.map((item) => {
                              return <Option key={item.type}>{item.desc}</Option>;
                            })
                            : null}
                      </Select>
                  )}
                </Form.Item>

                {this.props.form.getFieldValue("livePeriod3") === 2 ? (
                    <Form.Item
                        className="Supp_shi_qu"
                        label={
                          <span style={{ color: "transparent" }}> undefined</span>
                        }
                    >
                      <Form.Item label={undefined}>
                        {getFieldDecorator("resourceEducationLevel", {
                          initialValue: majorData.resourceEducationLevel,
                          //   rules: [{ required: true, message: "请输入本科" }],
                        })(
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="请选择本科"
                                optionFilterProp="children"
                                onChange={this.btn_onChange}
                            >
                              {majorArr
                                  ? majorArr.map((item, index) => {
                                    return (
                                        <Option value={item.type} key={index}>{item.desc}</Option>
                                    );
                                  })
                                  : null}
                            </Select>
                        )}
                      </Form.Item>
                      <Form.Item label={undefined}>
                        {getFieldDecorator("resourceMajorFirst", {
                          initialValue: majorData.resourceMajorFirst,
                          //   rules: [{ required: true, message: "请选择专业大类" }],
                        })(
                            <Select
                                showSearch
                                style={{ width: 200, marginLeft: 20 }}
                                placeholder="请选择专业大类"
                                optionFilterProp="children"
                                onChange={this.city_onChange}
                            >
                              {majorArrBig
                                  ? majorArrBig.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>{item.typeName}</Option>
                                    );
                                  })
                                  : null}
                            </Select>
                        )}
                      </Form.Item>

                      <Form.Item label={undefined}>
                        {getFieldDecorator("resourceMajorSecond", {
                          initialValue: majorData.resourceMajorSecond,

                          //   rules: [{ required: true, message: "请选择专业二分类" }],
                        })(
                            <Select
                                showSearch
                                style={{ width: 200, marginLeft: 20 }}
                                placeholder="请选择专业二分类"
                                optionFilterProp="children"
                                onChange={this.area_onChange}
                            >
                              {majorArrTwo
                                  ? majorArrTwo.map((item) => {
                                    return (
                                        <Option value={item.id}>{item.typeName}</Option>
                                    );
                                  })
                                  : null}
                            </Select>
                        )}
                      </Form.Item>
                    </Form.Item>
                ) : null}
                <Form.Item label="热门方向">
                  {getFieldDecorator("hotIds", {
                    rules: [{ required: true, message: "请选择热门方向" }],
                  })(
                      <Checkbox.Group
                          options={plainOptions}
                      />
                  )}
                </Form.Item>

                <Form.Item label="提供商">
                  {getFieldDecorator("supplierId", {
                    rules: [{ required: true, message: "请选择提供商" }],
                  })(
                      <Select
                          placeholder="请选择提供商"
                          optionFilterProp="children"
                          onChange={this.ti_onChange}
                      >
                        {this.state.Gong_ying
                            ? this.state.Gong_ying.map((item, index) => {
                              return (
                                  <Option key={item.supplierId}>
                                    {item.supplierName}
                                  </Option>
                              );
                            })
                            : null}
                      </Select>
                  )}
                </Form.Item>
                <Form.Item label="运行方式">
                  {getFieldDecorator("resourceRunMode", {
                    rules: [{ required: true, message: "请选择运行方式" }],
                  })(
                      <Select
                          placeholder="请选择运行方式"
                          optionFilterProp="children"
                          onChange={this.yun_onChange}
                      >

                        {this.state.runType
                            ? this.state.runType.map((item, index) => {

                              return <Option key={item.type}>{item.desc} </Option>;
                            })
                            : null}
                      </Select>
                  )}
                </Form.Item>
                I
                {
                  this.state.isshow && <>

                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? (
                        <Form.Item label="网址链接">
                          {getFieldDecorator("webUrl", {
                            rules: [{ required: true, message: "请输入网址链接" }],
                          })(
                              <MaxLengthInput
                                  placeholder="请输入网址链接"
                                  style={{ height: 40, paddingRight: 60 }}
                                  maxLength={100}
                              ></MaxLengthInput>
                          )}
                        </Form.Item>
                    ) : null}
                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="资源场景">
                          {getFieldDecorator("startTypes", {
                            rules: [{ required: true, message: "请选择资源场景" }],
                          })(
                              <Checkbox.Group>
                                {
                                  Array.isArray(this.state.Zi_qi_dong) && this.state.Zi_qi_dong.map((item, index) => {
                                    return <Checkbox
                                        value={item.type}
                                        // onChange={this.onChange_qi}
                                    >{item.desc}</Checkbox>
                                  })
                                }
                              </Checkbox.Group>
                              // 资源场景修改值： 1.引导 2.考核 3实训

                          )}


                        </Form.Item>
                    )}
                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="默认卸载地址">
                          {getFieldDecorator("defaultInstallPath", {
                            rules: [{ required: true, message: "请输入默认卸载地址" }],
                          })(
                              <MaxLengthInput
                                  placeholder="请输入默认卸载地址"
                                  style={{ height: 40, paddingRight: 60 }}
                                  maxLength={100}
                              ></MaxLengthInput>
                          )}
                          <span className='red'>
                        示例：<code>C:\Program Files (x86)\Machine\unins000.exe</code>
                      </span>

                        </Form.Item>
                    )}
                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="静默安装参数">
                          {getFieldDecorator("slientInstallParameter", {
                            //   rules: [{ required: true, message: "请输入静默安装参数" }],
                          })(
                              <MaxLengthInput
                                  placeholder="请输入静默安装参数"
                                  style={{ height: 40, paddingRight: 60 }}
                                  maxLength={100}
                              ></MaxLengthInput>
                          )}
                          <span className='red'>
                        示例：<code>/VERYSILENT /SUPPRESSMSGBOXES /NORESTART</code>
                      </span>
                        </Form.Item>
                    )}

                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="注册码">
                          {getFieldDecorator("resourceRegistryKey", {
                            rules: [{ required: true, message: "请输入注册码" }],
                          })(
                              <MaxLengthInput
                                  placeholder="请输入注册码"
                                  style={{ height: 40, paddingRight: 60 }}
                                  maxLength={100}
                              ></MaxLengthInput>
                          )}
                          <span className='red'>
                        示例：<code>YesVrDIY</code>
                      </span>
                        </Form.Item>
                    )}
                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="版本号">
                          {getFieldDecorator("resourceVersion", {
                            rules: [{ required: true, message: "请输入版本哈" }],
                          })(
                              <MaxLengthInput
                                  placeholder="请输入版本号"
                                  style={{ height: 40, paddingRight: 60 }}
                                  maxLength={10}
                              ></MaxLengthInput>
                          )}
                        </Form.Item>
                    )}
                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="安装名称">
                          {getFieldDecorator("appName", {
                            rules: [{ required: true, message: "请输入安装名称" }],
                          })(
                              <MaxLengthInput
                                  placeholder="请输入安装名称"
                                  style={{ height: 40, paddingRight: 60 }}
                                  maxLength={100}
                              ></MaxLengthInput>
                          )}
                        </Form.Item>
                    )}

                    <Form.Item label="适配硬件">
                      {getFieldDecorator("resourceAdaptiveHardware", {
                        rules: [{ required: true, message: "请选择适配硬件" }],
                      })(
                          <Select
                              placeholder="请选择适配硬件"
                              optionFilterProp="children"
                              onChange={this.shi_onChange}
                          >
                            {this.state.hardWareData
                                ? this.state.hardWareData.map((item, index) => {
                                  return <Option key={index} value={item.type}>{item.desc}</Option>;
                                })
                                : null}
                          </Select>
                      )}
                    </Form.Item>

                    <Form.Item label="适用场景">
                      {getFieldDecorator("resourceRange", {
                        rules: [{ required: true, message: "请输入适用场景" }],
                      })(
                          <MaxLengthInput
                              placeholder="请输入适用场景"
                              type={"textarea"}
                              isTextarea={true}
                              style={{
                                height: 100,
                                paddingRight: 60,
                                resize: "none",
                              }}
                              maxLength={300}
                          ></MaxLengthInput>
                      )}
                    </Form.Item>



                    {isTrue == 1 || isTrue == 3 || isTrue == 4 ? null : (
                        <Form.Item label="上传安装包">
                          {getFieldDecorator("livePeriod6665", {
                            rules: [{ required: true, message: "请上传安装包" }],
                          })(
                              <>
                                {console.log(this.state.uploadiIagesList, '33')}
                                {

                                  (this.state.uploadiIagesList?.length < 1 && !this.state.uploadiIagesList_loding) ?
                                      <div className="opload_l">

                                <span>
                                  {" "}
                                  <img
                                      alt=''
                                      src={window.$$img(
                                          "cvec-space-front-geren/zhihuijiaoxue/uploadimg.png"
                                      )}
                                  ></img>
                                </span>
                                        <Upload accept=".exe" {...uploadPwdDic}>
                                          <span className="upload-desc">上传文件</span>
                                        </Upload>
                                      </div>
                                      : <>
                                        {
                                          this.state.uploadiIagesList_loding ? (
                                              <Spin
                                                  spinning
                                                  indicator={
                                                    <>
                                                      {" "}
                                                      <LodingBase64 />
                                                      &emsp; 正在上传中...
                                                    </>
                                                  }
                                              ></Spin>
                                          ) : <div>
                                            {this.state.uploadiIagesList[0]?.originFileName || this.state.uploadiIagesList[0]?.resourceLoadOriginName}&emsp;
                                            <span onClick={() => {
                                              this.setState(() => ({ uploadiIagesList: [] }), () => {
                                                this.props.form.setFieldsValue({ 'livePeriod6665': undefined })
                                              })
                                            }}><Shanchuicon /></span >
                                          </div>
                                        }

                                      </>
                                }
                              </>

                          )}
                        </Form.Item>
                    )}

                    <Form.Item label="备注说明" style={{ marginTop: 50 }}>
                      {getFieldDecorator("resourceExplain", {
                        rules: [{ required: true, message: "请输入备注说明" }],
                      })(
                          <MaxLengthInput
                              placeholder="请输入备注说明"
                              type={"textarea"}
                              isTextarea={true}
                              style={{
                                height: 100,
                                paddingRight: 60,
                                resize: "none",
                              }}
                              maxLength={300}
                          ></MaxLengthInput>
                      )}
                    </Form.Item>

                    {/* {isTrue == 1 || isTrue == 3 ? null : ( */}
                    <Form.Item label="章节目录">
                      {getFieldDecorator("isChapter", {
                        rules: [{ required: true, message: "请选择章节目录" }],

                      })(
                          <Radio.Group onChange={(e) => {
                            this.setState({
                              value: e.target.value
                            })
                          }} value={this.state.value}
                          >
                            <Radio value={0}>无</Radio>

                            <Radio value={1}>有</Radio>
                          </Radio.Group>
                      )}
                    </Form.Item>


                    {/* {
                    this.state.isup === "add" && this.state.value == 1 ? <Button style={{ width: 111, marginLeft: 149 }} onClick={() => {
                      Modal.confirm({
                        title: "是否添加章节目录",
                        content: "",
                        onOk: () => {
                          this._prop_toog_yesDIR()
                          this.addhandleOk()
                          this.setState(() => {
                            return {
                              addvisible: false
                            }
                          })
                        },
                        onCancel: () => {

                        }
                      })
                    }}>新增章节目录</Button> : ''
                  } */}



                    {this.state.isup !== "add" && this.state.value === 1 && (
                        <Form.Item className={'labelNotSmile'} label={<>&emsp;&emsp;&emsp;&emsp;</>}>
                          <Button style={{ width: 114, height: 32 }} onClick={() => {
                            this.handlerEditDir()
                          }}>编辑章节目录</Button>
                        </Form.Item>
                    )}
                  </>
                }

              </Form>
            </Modal>






            {/*22222222222222222222222222222222222222222222222222222222 查看章节目录 */}

            <Modal
                title={
                  <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
                    {/* 资源名称1 */}

                    {
                      dataSource.find((v, i) => {

                        return v.resourceId === this.state.curSelectSoure.resourceId

                      })?.resourceName
                      // console.log(v.resourceId===this.state.curSelectSoure)
                    }

                  </div>
                }
                visible={this.state.visibleEcho}
                bodyStyle={{ height: 250 }}
                width={660}
                centered
                closable={false}
                footer={null}
                wrapClassName="curr_delete_Ecoh"
                destroyOnClose
            >
              <div>
                <p>
                  <HuiXian />
                </p>

                <p style={{ marginTop: 20, paddingLeft: 42 }}>
                  <span style={{ color: "#333", fontSize: 14 }}>链接有效期</span>

                  <span>
                  <Select
                      showSearch
                      style={{ width: 330, marginLeft: 20 }}
                      placeholder="请选择有效期"
                      optionFilterProp="children"
                      onChange={this.zi_onChange}
                  >
                    {this.state.validity
                        ? this.state.validity.map((item) => {
                          return <Option value={item.type}>{item.desc}</Option>;
                        })
                        : null}
                  </Select>
                </span>
                </p>
                <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      borderTop: "1px solid #e8e8e8",
                      padding: "10px 16px",
                      textAlign: "right",
                      left: 0,
                      background: "#fff",
                      borderRadius: "0 0 4px 4px",
                    }}
                >
                  <Button
                      onClick={this.visibleEchoCancel}
                      style={{ width: 98, height: 40, marginRight: 20 }}
                  >
                    取消
                  </Button>

                  <Button
                      onClick={this.visibleEchoTWOModal}
                      type="primary"
                      style={{ width: 128, height: 40 }}
                  >
                    创建地址链接
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
                title={
                  <div style={{ fontWeight: 500, color: "#333", fontSize: 18 }}>
                    {/* 资源名称 */}
                    {
                      dataSource.find((v, i) => {

                        return v.resourceId === this.state.curSelectSoure.resourceId

                      })?.resourceName
                      // console.log(v.resourceId===this.state.curSelectSoure)
                    }

                  </div>
                }
                visible={this.state.visibleEchoTWO}
                bodyStyle={{ height: 330 }}
                width={660}
                centered
                closable={false}
                footer={null}
                destroyOnClose
                wrapClassName="details_delete_curriun"
            >
              <div>
                <p>
                  <HuiXian />
                </p>
                <p style={{ marginTop: 20, paddingLeft: 42 }}>
                <span
                    style={{
                      color: "#333",
                      fontSize: 14,
                      width: 80,
                      display: "inline-block",
                      textAlign: "right",
                    }}
                >
                  链接有效期
                </span>

                  <span>
                  {" "}
                    <Input placeholder="请输入链接有效期" disabled value={this.state.addressPassword.downloadPageUrl} style={{ width: 347, height: 40, marginLeft: 10 }} />
                </span>
                </p>
                <p style={{ marginTop: 20, paddingLeft: 42 }}>
                <span
                    style={{
                      color: "#333",
                      fontSize: 14,
                      width: 80,
                      display: "inline-block",
                      textAlign: "right",
                    }}
                >
                  提取密码
                </span>

                  <span>
                  {" "}
                    <Input placeholder="请输入提取密码" disabled value={this.state.addressPassword.password} style={{ width: 347, height: 40, marginLeft: 10 }} />
                </span>
                </p>

                <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      borderTop: "1px solid #e8e8e8",
                      padding: "10px 16px",
                      textAlign: "right",
                      left: 0,
                      background: "#fff",
                      borderRadius: "0 0 4px 4px",
                    }}
                >
                  <Button
                      onClick={this.visibleEchoTWOCancel}
                      style={{ width: 98, height: 40, marginRight: 20 }}
                  >
                    取消

                  </Button>

                  <Button
                      onClick={this.Installation_anzhuangbaoModal}
                      type="primary"
                      style={{ width: 128, height: 40 }}
                  >
                    创建地址链接
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
                title={
                  <div className="title">
                    <div
                        style={{
                          fontWeight: 500,
                          color: "#333",
                          fontSize: 18,
                          lineHeight: "38px",
                        }}
                    >
                      安装包
                    </div>
                    <div>
                      <span style={{ marginRight: 20 }}>手机号</span>
                      <span>
                    <SouT></SouT>
                    <Input
                        placeholder="请输入手机号"
                        style={{
                          position: "relative",
                          width: 330,
                          paddingLeft: 27,
                        }}
                        onChange={this.searchVlueChange}
                        onPressEnter={this.searchEnter}
                    />
                  </span>
                    </div>
                  </div>
                }
                visible={this.state.Installation_anzhuangbao}
                bodyStyle={{ height: 350 }}
                width={800}
                centered
                closable={false}
                footer={null}
                destroyOnClose
                wrapClassName="installation_Hui"
            >
              {this.state.isOneOpen && (
                  <div>
                    <Empty
                        image={window.$$imgSrc(
                            "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"
                        )}
                        description={"暂无内容"}
                    />
                  </div>
              )}

              {!this.state.isOneOpen && this.state.teacherInfo ?
                  (
                      <div>
                        <div className="sou_dao_l">
                          <div>
                            <div>
                              <span></span>
                              <span>使用者详情</span>{" "}
                            </div>
                            <div>
                              <span><img alt='' src={window.$$imgSrc(this.state.teacherInfo.portraitId ? this.state.teacherInfo.portraitId : 'base/image/227219bf6b344a098daf95bec40eb473.jpg_s.jpg')} /></span>
                              <span>
                          <span>{this.state.teacherInfo.userName}-{this.state.teacherInfo.enterpriseName}</span>

                          <span>
                            <span>
                              部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门:
                            </span>
                            <span style={{ marginLeft: 5 }}> {this.state.teacherInfo.factoryName}-{this.state.teacherInfo.positions}</span>
                          </span>
                          <span>
                            <span>手机号码:</span>{" "}
                            <span style={{ marginLeft: 5 }}>{this.state.teacherInfo.telNum}</span>
                          </span>
                        </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  ) : !this.state.isOneOpen ? (
                      <div>
                        <Empty
                            image={window.$$imgSrc(
                                "base/ab/image/dbc0668148d34e2cbfd4e57f400707e2.jpg"
                            )}
                            description={"没有找到您想要的内容"}
                        />
                      </div>
                  ) : null}

              {/* <div> */}


              <div>
                <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      borderTop: "1px solid #e8e8e8",
                      padding: "10px 16px",
                      textAlign: "right",
                      left: 0,
                      background: "#fff",
                      borderRadius: "0 0 4px 4px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                >
                  <div className="footer_wen">
                  <span>
                    <Checkbox onChange={this.onChange_check}></Checkbox>
                  </span>
                    <span>
                    站内信通知的同时，向使用者手机发送手机短信通知提取地址和密码
                  </span>
                  </div>


                  <div>
                    <Button
                        onClick={this.Installation_anzhuangbaoCancel}
                        style={{ width: 98, height: 40, marginRight: 20 }}
                    >
                      取消
                    </Button>

                    <Button
                        onClick={this.Installation_anzhuangbaoOk}
                        type="primary"
                        style={{ width: 108, height: 40 }}
                    >
                      确定发送
                    </Button>
                  </div>


                </div>
              </div>
            </Modal>
          </div>
          {/* //新增的时候的选择有章节目录的时候的资源的章节目录 */}
          <EditdirModule
              isAdd={this.state.isup === 'add'}
              key={this.state.yesDIR}
              toog_addDIR_itemfn={this._prop_toog_addDIR_itemfn}
              visible={this.state.yesDIR}
              prop_toog_yesDIR={this._prop_toog_yesDIR}
              edit__leftLOOKdirList={this.state.edit__leftLOOKdirList}
              get_edit__leftLOOKdirList={this.get_edit__leftLOOKdirList}
              resourceId={this.state.resourceId}
          />

          <LookDir closable={false}
                   data={this.state.__lookData
                   } visible={this.state.lookDirvisible}
                   onOk={() => { this.setState({ lookDirvisible: false }) }}
                   onCancel={() => { this.setState({ lookDirvisible: false }) }} />

          <AddZhangJie
              toog_addDIR_itemfn={this._prop_toog_addDIR_itemfn}
              visible={this.state.addDIR_item}
              get_edit__leftLOOKdirList={this.get_edit__leftLOOKdirList}
              resourceId={this.state.resourceId}
              resourceType={this.state.resourceType}

          />
        </div>
    );
  }
}
export default Form.create()(index);
